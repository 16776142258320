import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Layout from "./pages/Layout";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import PrivateAdventure from "./pages/PrivateAdventure";
import TermsAndConditions from "./pages/TermsAndConditions";
import CancellationPolicy from "./pages/CancellationPolicy";
import NoPage from "./pages/NoPage";

import AncientTrails from "./pages/ancient-trails";
import Laugavegur from "./pages/laugavegur";
import Hornstrandir from "./pages/hornstrandir";
import FireAndIce from "./pages/fire-and-ice";
import MountainRetreat from "./pages/mountain-retreat";
import WinterWonderland from "./pages/winter-wonderland";

import "./output.css";
import "./index.css";

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="adventures">
            <Route index element={<Navigate to="/" replace />} />
            <Route path="ancient-trails" element={<AncientTrails />} />
            <Route path="laugavegur" element={<Laugavegur />} />
            <Route path="hornstrandir" element={<Hornstrandir />} />
            <Route path="fire-and-ice" element={<FireAndIce />} />
            <Route path="mountain-retreat" element={<MountainRetreat />} />
            <Route path="winter-wonderland" element={<WinterWonderland />} />
          </Route>
          <Route path="private-adventure" element={<PrivateAdventure />} />
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="cancellation-policy" element={<CancellationPolicy />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
