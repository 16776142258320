import * as React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import Footer from "../components/Footer";
import Reviews from "../components/Reviews";

export default class FireAndIce extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navActive: false,
      screenWidth: window.innerWidth,
    };

    this.hideNav = this.hideNav.bind(this);
    this.showNav = this.showNav.bind(this);
  }

  hideNav() {
    this.setState({ navActive: false });
  }

  showNav() {
    this.setState({ navActive: true });
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    if (this.state.navActive && window.innerWidth < 1440) {
      document.body.classList.add("overflow-hidden");
    } else document.body.classList.remove("overflow-hidden");

    window.addEventListener("resize", () => {
      this.setState({
        screenWidth: window.innerWidth,
      });
      if (this.state.navActive && window.innerWidth < 1440) {
        document.body.classList.add("overflow-hidden");
      } else document.body.classList.remove("overflow-hidden");
    });

    let bokunScript = document.createElement("script");
    bokunScript.src =
      "https://widgets.bokun.io/assets/javascripts/apps/build/BokunWidgetsLoader.js?bookingChannelUUID=6b0f06ca-351c-45c7-bb92-cd248126f18f";
    document.body.appendChild(bokunScript);
  }

  componentDidUpdate() {
    if (this.state.navActive && window.innerWidth < 1440) {
      document.body.classList.add("overflow-hidden");
    } else document.body.classList.remove("overflow-hidden");
  }

  render() {
    return (
      <div className="App flex flex-col items-center">
        <div className="bg-red-500 text-white w-full p-2">
          The website is in development so you can expect some errors or display
          problems. Not all buttons and links work.
        </div>

        <header
          className={
            "flex flex-col items-center desktop:justify-center w-full group" +
            (this.state.navActive & (this.state.screenWidth < 1440)
              ? " absolute"
              : " h-screen relative")
          }
        >
          <div
            className={
              "z-40 flex flex-row items-center justify-center w-full desktop:h-auto desktop:absolute desktop:top-0 desktop:left-0" +
              (this.state.navActive & (this.state.screenWidth < 1440)
                ? " h-screen flex-col"
                : "")
            }
          >
            <div
              className={
                "w-full h-full desktop:max-w-[1280px] flex bg-neutral-900 text-white desktop:m-16" +
                (this.state.navActive & (this.state.screenWidth < 1440)
                  ? " flex-col items-center justify-center"
                  : " flex-row bg-opacity-90")
              }
            >
              <a href="/">
                <div className="p-8 hover:bg-neutral-900">
                  <img
                    src="/runes.svg"
                    alt="Norse Logo"
                    className="invert w-48"
                  />
                </div>
              </a>
              <div
                id="navlinks"
                className={
                  "flex flex-col desktop:flex-row desktop:ml-auto text-xl font-black" +
                  (!this.state.navActive ? " ml-auto" : "")
                }
              >
                {this.state.navActive || this.state.screenWidth >= 1440 ? (
                  <>
                    <HashLink
                      onClick={this.hideNav}
                      className="p-8 hover:bg-neutral-900 h-full flex flex-col justify-center"
                      to="/#adventures"
                    >
                      ADVENTURES
                    </HashLink>
                    <Link
                      onClick={this.hideNav}
                      className="p-8 hover:bg-neutral-900 h-full flex flex-col justify-center"
                      to="/about-us"
                    >
                      ABOUT US
                    </Link>
                    <a
                      onClick={this.hideNav}
                      className="p-8 hover:bg-neutral-900 h-full flex flex-col justify-center"
                      href="#contact-us"
                    >
                      CONTACT US
                    </a>
                  </>
                ) : (
                  <button onClick={this.showNav} className="text-3xl p-8">
                    ☰
                  </button>
                )}
              </div>
            </div>
          </div>

          {this.state.navActive && (
            <button
              onClick={this.hideNav}
              className="text-3xl p-8 absolute top-0 right-0 text-white z-50"
            >
              ☰
            </button>
          )}

          <img
            src="/img/26.jpg"
            alt="A man standing on a cliff overlooking Hrafntinnusker"
            className="w-full h-full object-cover group-hover:brightness-110"
          />
          <div className="absolute w-full h-full bg-black bg-opacity-20"></div>
          <div className="absolute h-full w-full flex flex-col items-start justify-end p-4 laptop:p-16 max-w-[1408px]">
            <div className="flex flex-col gap-4 mb-16 text-white">
              <span className="text-4xl tablet:text-5xl laptop:text-6xl laptop-lg:text-7xl desktop:text-8xl font-black drop-shadow-md uppercase">
                Fire and Ice
              </span>
              <span className="font-bold text-lg">Hike and drive to combine the best of sightseeing and the highlands</span>
              <div className="flex flex-row flex-wrap gap-8">
                <div className="flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10 block mr-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>
                    <span className="block font-bold">Duration</span>7 days / 6 nights
                  </span>
                </div>
                <div className="flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10 block mr-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>
                    <span className="block font-bold">Difficulty</span>
                    Intermediate
                  </span>
                </div>
                <div className="flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10 block mr-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                  </svg>
                  <span>
                    <span className="block font-bold">Accommodation</span>
                    Hotels
                  </span>
                </div>
                <div className="flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10 block mr-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
                  </svg>
                  <span>
                    <span className="block font-bold">Group size</span>
                    Up to 14 participants
                  </span>
                </div>
              </div>
              <div className="flex flex-row items-center gap-4">
                <button
                disabled data-src="https://widgets.bokun.io/online-sales/6b0f06ca-351c-45c7-bb92-cd248126f18f/experience/965490?partialView=1" data-testid="widget-book-button"
                className="bokunButton bg-yellow-200 hover:bg-yellow-300 text-black text-xl w-auto px-6 py-3 rounded-full flex flex-row items-center gap-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                    <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z" clip-rule="evenodd" />
                  </svg>
                  <span>Book now</span>
                </button>
                <span className="text-xl font-bold">$3000/person</span>
                <style>{`#bokun-modal-container{max-width: 496px}`}</style>
              </div>
            </div>
          </div>
        </header>
        
        <main class="flex flex-col w-full p-4 laptop:p-16 max-w-[1408px] py-16">
          <section class="flex flex-col gap-8 text-lg">
            <p>
              <span class="text-red-500 font-medium">Apologies.</span> This page
              is under construction.
            </p>
            <p>
              You can contact us at{" "}
              <a
                className="text-blue-500 font-medium"
                href="mailto:adventures@norse.is"
              >
                adventures@norse.is
              </a>{" "}
              if you want more information about this adventure.
            </p>
          </section>
        </main>
        
        <Reviews />

        <Footer />
      </div>
    );
  }
}
