import * as React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import Footer from "../components/Footer";
import Reviews from "../components/Reviews";

import { Accordion, Placeholder, Modal } from 'rsuite';
import 'rsuite/dist/rsuite-no-reset.min.css';

export default class AncientTrails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navActive: false,
      screenWidth: window.innerWidth,
    };

    this.hideNav = this.hideNav.bind(this);
    this.showNav = this.showNav.bind(this);
  }

  hideNav() {
    this.setState({ navActive: false });
  }

  showNav() {
    this.setState({ navActive: true });
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    if (this.state.navActive && window.innerWidth < 1440) {
      document.body.classList.add("overflow-hidden");
    } else document.body.classList.remove("overflow-hidden");

    window.addEventListener("resize", () => {
      this.setState({
        screenWidth: window.innerWidth,
      });
      if (this.state.navActive && window.innerWidth < 1440) {
        document.body.classList.add("overflow-hidden");
      } else document.body.classList.remove("overflow-hidden");
    });

    let bokunScript = document.createElement("script");
    bokunScript.src =
      "https://widgets.bokun.io/assets/javascripts/apps/build/BokunWidgetsLoader.js?bookingChannelUUID=6b0f06ca-351c-45c7-bb92-cd248126f18f";
    document.body.appendChild(bokunScript);
  }

  componentDidUpdate() {
    if (this.state.navActive && window.innerWidth < 1440) {
      document.body.classList.add("overflow-hidden");
    } else document.body.classList.remove("overflow-hidden");
  }

  render() {
    return (
      <div className="App flex flex-col items-center">

        <div className="bg-red-500 text-white w-full p-2">
          The website is in development so you can expect some errors or display
          problems. Not all buttons and links work.
        </div>

        <header
          className={
            "flex flex-col items-center desktop:justify-center w-full group" +
            (this.state.navActive & (this.state.screenWidth < 1440)
              ? " absolute"
              : " h-screen relative")
          }
        >
          <div
            className={
              "z-40 flex flex-row items-center justify-center w-full desktop:h-auto desktop:absolute desktop:top-0 desktop:left-0" +
              (this.state.navActive & (this.state.screenWidth < 1440)
                ? " h-screen flex-col"
                : "")
            }
          >
            <div
              className={
                "w-full h-full desktop:max-w-[1280px] flex bg-neutral-900 text-white desktop:m-16" +
                (this.state.navActive & (this.state.screenWidth < 1440)
                  ? " flex-col items-center justify-center"
                  : " flex-row bg-opacity-90")
              }
            >
              <a href="/">
                <div className="p-8 hover:bg-neutral-900">
                  <img
                    src="/runes.svg"
                    alt="Norse Logo"
                    className="invert w-48"
                  />
                </div>
              </a>
              <div
                id="navlinks"
                className={
                  "flex flex-col desktop:flex-row desktop:ml-auto text-xl font-black" +
                  (!this.state.navActive ? " ml-auto" : "")
                }
              >
                {this.state.navActive || this.state.screenWidth >= 1440 ? (
                  <>
                    <HashLink
                      onClick={this.hideNav}
                      className="p-8 hover:bg-neutral-900 h-full flex flex-col justify-center"
                      to="/#adventures"
                    >
                      ADVENTURES
                    </HashLink>
                    <Link
                      onClick={this.hideNav}
                      className="p-8 hover:bg-neutral-900 h-full flex flex-col justify-center"
                      to="/about-us"
                    >
                      ABOUT US
                    </Link>
                    <a
                      onClick={this.hideNav}
                      className="p-8 hover:bg-neutral-900 h-full flex flex-col justify-center"
                      href="#contact-us"
                    >
                      CONTACT US
                    </a>
                  </>
                ) : (
                  <button onClick={this.showNav} className="text-3xl p-8">
                    ☰
                  </button>
                )}
              </div>
            </div>
          </div>

          {this.state.navActive && (
            <button
              onClick={this.hideNav}
              className="text-3xl p-8 absolute top-0 right-0 text-white z-50"
            >
              ☰
            </button>
          )}

          <video
            className="w-full h-full object-cover brightness-125 group-hover:brightness-150"
            loop
            autoPlay
            mute
          >
            <source src="/img/DJI_20240813233742_0005_D_6.mp4" type="video/mp4"></source>
            Your browser does not support the video tag.
          </video>
          <div className="absolute h-full w-full flex flex-col items-start justify-end p-4 laptop:p-16 max-w-[1408px]">
            <div className="flex flex-col gap-4 mb-16 text-white">
              <span className="text-4xl tablet:text-5xl laptop:text-6xl laptop-lg:text-7xl desktop:text-8xl font-black drop-shadow-md uppercase">
                Ancient Trails
              </span>
              <span className="font-bold text-lg">Go deep in the central highlands and hike old Viking paths away from the crowds</span>
              <div className="flex flex-row flex-wrap gap-8">
                <div className="flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10 block mr-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>
                    <span className="block font-bold">Duration</span>6 days / 5 nights
                  </span>
                </div>
                <div className="flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10 block mr-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>
                    <span className="block font-bold">Difficulty</span>
                    Intermediate
                  </span>
                </div>
                <div className="flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10 block mr-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 1.586l-4 4v12.828l4-4V1.586zM3.707 3.293A1 1 0 002 4v10a1 1 0 00.293.707L6 18.414V5.586L3.707 3.293zM17.707 5.293L14 1.586v12.828l2.293 2.293A1 1 0 0018 16V6a1 1 0 00-.293-.707z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>
                    <span className="block font-bold">Total distance</span>
                    60 km (38 mi)
                  </span>
                </div>
                <div className="flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10 block mr-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                  </svg>
                  <span>
                    <span className="block font-bold">Accommodation</span>
                    Mountain huts
                  </span>
                </div>
                <div className="flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10 block mr-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
                  </svg>
                  <span>
                    <span className="block font-bold">Group size</span>
                    Up to 10 participants
                  </span>
                </div>
              </div>
              <div className="flex flex-row items-center gap-4">
                <button
                disabled data-src="https://widgets.bokun.io/online-sales/6b0f06ca-351c-45c7-bb92-cd248126f18f/experience/728916?partialView=1" data-testid="widget-book-button"
                className="bokunButton bg-yellow-200 hover:bg-yellow-300 text-black text-xl w-auto px-6 py-3 rounded-full flex flex-row items-center gap-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                    <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z" clip-rule="evenodd" />
                  </svg>
                  <span>Book now</span>
                </button>
                <span className="text-xl font-bold">$3500/person</span>
                <style>{`#bokun-modal-container{max-width: 496px}`}</style>
              </div>
            </div>
          </div>
        </header>

        <div className="description-content flex flex-row p-4 laptop:p-16 w-full max-w-screen-desktop">
          <div class="flex flex-col">
            <div className="flex flex-row">
              <div>
              <p className="text-3xl font-bold text-black lg:mx-auto">
                Intro
              </p>
              <p className="mt-4 text-lg text-black lg:mx-auto">
                The story of Iceland is written in its landscapes and best told through its hiking trails. Get ready for a trek through time—past ancient glaciers, epic geysers, legendary Viking footpaths, centennial hiker's huts—and uncover the forces that have shaped the Land of Fire and Ice for millions of years. You'll walk along the same paths that Viking settlers, farmers, explorers, and armies were once using to transit between the north and the south. This path is shrouded in myth and legend, very remote, and was once even full of trolls, elves, ghosts, and outlaws! Step onto the trail and hike over lava fields, between glaciers, over rivers, and canyons, and at the end of each day, rest your weary feet in cozy huts. You'll taste traditional dishes and unwind in natural hot springs like travelers of the old. Experience the legacy and history of Iceland in this single hike.
              </p>
              <div className="flex text-lg flex-row mt-8 gap-4">
                <div className="flex flex-col">
                  <div className="w-full flex mb-2">
                    <span className="bg-gray-200 rounded-full p-2">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                        <path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clip-rule="evenodd" />
                      </svg>
                    </span>
                  </div>
                  <span className="font-bold flex flex-row gap-2 items-center">
                    <span>Get to know Iceland's legacy</span>
                  </span>
                  <span>See a unique blend of historic landmarks and timeless landscapes</span>
                </div>
                <div className="flex flex-col">
                <div className="w-full flex mb-2">
                      <span className="bg-gray-200 rounded-full p-2">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                          <path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clip-rule="evenodd" />
                        </svg>
                      </span>
                    </div>
                <span className="font-bold flex flex-row gap-2 items-center">
                    <span>Hot springs & tasty food</span>
                  </span>
                  <span>Dive into the island's culture and delicious traditional cuisine</span>
                </div>
                <div className="flex flex-col">
                <div className="w-full flex mb-2">
                    <span className="bg-gray-200 rounded-full p-2">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                        <path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clip-rule="evenodd" />
                      </svg>
                    </span>
                  </div>
                <span className="font-bold flex flex-row gap-2 items-center">
                  
                    <span>Follow the Vikings' footsteps</span>
                  </span>
                  <span>The Ancient Trail of Kjalvegur is one of the oldest passable highland routes</span>
                </div>
              </div>
            </div>
          </div>
          <section className="text-gray-700">
            <div className="grid grid-cols-3 gap-4 mt-8">
              <div className="overflow-hidden flex flex-wrap w-full h-72">
                <div className="w-full h-full">
                  <img
                    alt="gallery"
                    className="block object-cover object-center w-full h-full rounded-lg hover:brightness-110 cursor-pointer"
                    src="/img/at1/min/at-1-1920.jpg"
                  />
                </div>
              </div>
              <div className="overflow-hidden flex flex-wrap w-full h-72">
                <div className="w-full h-full">
                  <img
                    alt="gallery"
                    className="block object-cover object-center w-full h-full rounded-lg hover:brightness-110 cursor-pointer"
                    src="/img/at1/min/at-5-1920.jpg"
                  />
                </div>
              </div>
              <div className="overflow-hidden flex flex-wrap w-full h-72">
                <div className="w-full h-full">
                  <img
                    alt="gallery"
                    className="block object-cover object-center w-full h-full rounded-lg hover:brightness-110 cursor-pointer"
                    src="/img/at1/min/at-7-1920.jpg"
                  />
                </div>
              </div>
              <div className="overflow-hidden flex flex-wrap w-full h-72">
                <div className="w-full h-full">
                  <img
                    alt="gallery"
                    className="block object-cover object-center w-full h-full rounded-lg hover:brightness-110 cursor-pointer"
                    src="/img/at1/min/at-8-1920.jpg"
                  />
                </div>
              </div>
              <div className="overflow-hidden flex flex-wrap w-full h-72">
                <div className="w-full h-full">
                  <img
                    alt="gallery"
                    className="block object-cover object-center w-full h-full rounded-lg hover:brightness-110 cursor-pointer"
                    src="/img/at1/min/at-3-1920.jpg"
                  />
                </div>
              </div>
              <div className="overflow-hidden flex flex-wrap w-full h-72">
                <div className="w-full h-full">
                  <img
                    alt="gallery"
                    className="block object-cover object-center w-full h-full rounded-lg hover:brightness-110 cursor-pointer"
                    src="/img/at1/min/at-11-1920.jpg"
                  />
                </div>
              </div>
            </div>
        </section>
          </div>
        </div>

        <div className="flex flex-col p-4 laptop:p-16 w-full max-w-[1408px] pt-0 laptop:pt-0">
          <h2 className="text-3xl font-bold text-black lg:mx-auto">
            Itinerary
          </h2>
          <p className="mt-4 text-lg text-black lg:mx-auto">
            Leave the touristy trails behind and uncover the rich history behind Iceland's iconic landscapes on this 6-day hiking tour! Explore a spectacular mix of natural landmarks, ranging from geysers and colorful rhyolite mountains to timeless glaciers, while learning about their legacy from a seasoned local guide. Spend your time off the trails unwinding in scenic mountain huts, chilling in saunas, and tasting traditional Icelandic delicacies.  
          </p>
          <Accordion>
            <Accordion.Panel className="-mx-[20px]" header="Day 1: Troll Mountains" defaultExpanded>
              <div className="flex flex-row gap-8">
                <div className="flex flex-col gap-4">
                  <p className="flex flex-col">
                    <span><strong>Pick-up time:</strong> 10 am (10:00)</span>
                    <span><strong>Meeting location:</strong> BSI Bus Terminal in Reykjavik</span>
                  </p>

                  <p>
                    Your guide will pick you up at 10 am at the BSI bus terminal in Reykjavik and drive to Thingvellir national park where we will take a walk and explore the Mid-Atlantic Rift, and learn about the history of Iceland. After that we head on to Efstidalur farm and restaurant where we eat dinner in a barn and have a culinary introduction from the farmers. After a good meal, drive to Geysir geothermal area and witness the geysers erupting boiling hot water high up in the air! Finally we head into the wilderness of the highlands and drive to our first hut.
                  </p>

                  <p className="flex flex-col">
                    <span><strong>Meals:</strong> Lunch and dinner</span>
                    <span><strong>Accommodation:</strong> Hut</span>
                  </p>
                </div>
                <img
                    alt="gallery"
                    className="block object-cover object-center w-96 h-full rounded-lg"
                    src="/img/NorseAdventures_2024 6-resize-compress.jpg"
                  />
              </div>
            </Accordion.Panel>
            <Accordion.Panel className="-mx-[20px]" header="Day 2: Hot Spring Valley">
            <div className="flex flex-row gap-8">
                <div className="flex flex-col gap-4">
                  <p>
                    After breakfast, pack up your luggage and place it in the car. Today you'll hike the out-and-back trail to the Hveradalir geothermal area and hike a loop around the valley to soak in all the amazing sights. You'll encounter a spectacular array of colors, including, yellow, blue, black, green, and red from the iron and the rhyolite that the mountains are composed of. After the hike, get transported to the Hveravellir hut, where you will spend the night, and have an amazingly unique dinner in a turf house. Make sure to take a dip in the nearby geothermal pool before hitting the hay.
                  </p>

                  <p className="flex flex-col">
                    <span><strong>Meals:</strong> Breakfast, lunch, and dinner</span>
                    <span><strong>Accommodation:</strong> Hut</span>
                    <span><strong>Hiking:</strong> 7 miles / 12 km</span>
                    <span><strong>Elevation change:</strong> 1,970 ft / 600 m</span>
                  </p>
                </div>
                <img
                    alt="gallery"
                    className="block object-cover object-center w-96 h-full rounded-lg"
                    src="/img/10.webp"
                  />
              </div>
            </Accordion.Panel>
            <Accordion.Panel className="-mx-[20px]" header="Day 3: Valley of Thieves">
            <div className="flex flex-row gap-8">
                <div className="flex flex-col gap-4">
                  <p>
                    A spectacular hike awaits today along the ancient Kjalvegur route, once used by Vikings, settlers, and farmers. The route winds between the Langjokull and Hofsjokull glaciers, and you'll walk through unique vegetation, grass, flowers, and brush, but also black lava fields, volcanic craters, mountains, and glaciers. Hike to the top of Raudkollur mountain (Redhead Mountain) and enjoy unrivaled views of the glaciers and rivers snaking their way through the valleys and mountains.
                  </p>

                  <p className="flex flex-col">
                    <span><strong>Meals:</strong> Breakfast, lunch and dinner</span>
                    <span><strong>Accommodation:</strong> Hut</span>
                    <span><strong>Hiking:</strong> 7-11 miles / 11-18 km</span>
                    <span><strong>Elevation change:</strong> 490-1,970 ft / 150-600 m</span>
                  </p>
                </div>
                <img
                    alt="gallery"
                    className="block object-cover object-center w-96 h-full rounded-lg"
                    src="/img/at1/min/at-11-1920.jpg"
                  />
              </div>
            </Accordion.Panel>
            <Accordion.Panel className="-mx-[20px]" header="Day 4: Follow the Cairns">
            <div className="flex flex-row gap-8">
                <div className="flex flex-col gap-4">
                  <p>
                    Today, you'll follow the ancient cairns built by the Vikings a thousand years ago, and walk along the Fulakvisl river on the edge of the Kjalhraun lava field while enjoying a spectacular view of the Hrutfell mountain and its gliding glaciers throughout. Watch the Fulakvisl river roar through a very narrow canyon carved out by lava millennia ago. The scenery around the Thverbrekknamuli hut is so incredible you'll want to stay there and explore it for days on end. It's a true highland oasis.
                  </p>

                  <p className="flex flex-col">
                    <span><strong>Meals:</strong> Breakfast, lunch and dinner</span>
                    <span><strong>Accommodation:</strong> Hut</span>
                    <span><strong>Hiking:</strong> 9 miles / 14 km</span>
                    <span><strong>Elevation change:</strong> 330 ft / 100 m</span>
                  </p>
                </div>
                <img
                    alt="gallery"
                    className="block object-cover object-center w-96 h-full rounded-lg"
                    src="/img/NorseAdventures_2024 89.jpg"
                  />
              </div>
            </Accordion.Panel>
            <Accordion.Panel className="-mx-[20px]" header="Day 5: Ghosts and Glaciers">
            <div className="flex flex-row gap-8">
                <div className="flex flex-col gap-4">
                  <p>
                    On day 5, you'll hike to one of the oldest mountain huts in Iceland, Hvitarnes. Built way back in 1930, the hut and its turf roof are protected by Icelandic law because of its age and uniqueness. Follow a trail through vegetated areas interspaced by some deep horse trails and crystal-clear water fountains. The hut is close to the Hvitarvatn glacial lake, which runs from the Langjokull glacier and is the source of both the Hvita river and Gullfoss falls. There is an ice cold river just outside the hut, and those who dare may take a dip!
                  </p>

                  <p className="flex flex-col">
                    <span><strong>Meals:</strong> Breakfast, lunch and dinner</span>
                    <span><strong>Accommodation:</strong> Hut</span>
                    <span><strong>Hiking:</strong> 10 miles / 16 km</span>
                    <span><strong>Elevation change:</strong> 880 ft/ 270 m</span>
                  </p>
                </div>
                <img
                    alt="gallery"
                    className="block object-cover object-center w-96 h-full rounded-lg"
                    src="/img/at1/min/at-7-1920.jpg"
                  />
              </div>
            </Accordion.Panel>
            <Accordion.Panel className="-mx-[20px]" header="Day 6: Back to Civilization">
            <div className="flex flex-row gap-8">
                <div className="flex flex-col gap-4">
                  <p className="flex flex-col">
                    <span><strong>Drop-off time:</strong> 3 pm (15:00)</span>
                  </p>

                  <p>
                    Time to head back out of the highlands. We eat our breakfast and drive towards the waterfall Gullfoss, where we stop and enjoy magnificent views of this beautiful waterfall, and then eat lunch here. After a good last lunch together, we drive back to Reykjavik along a different road than the one we came with. That marks the official end of the tour. If you’d like to stay longer and keep on exploring the area, just ask your guides for some recommendations!
                  </p>

                  <p className="flex flex-col">
                    <span><strong>Meals:</strong> Breakfast and lunch</span>
                  </p>
                </div>
                <img
                    alt="gallery"
                    className="block object-cover object-center w-96 h-full rounded-lg"
                    src="/img/gullfoss-waterfall-landmark-panorama-720x420.jpg"
                  />
              </div>
            </Accordion.Panel>
          </Accordion>
        </div>

        <div className="flex flex-row gap-24 p-4 laptop:p-16 w-full max-w-[1408px] pt-0 laptop:pt-0">
          <div className="">
            <p className="text-xl font-bold text-black lg:mx-auto">
              Included
            </p>
            <p className="mt-4 text-black lg:mx-auto flex flex-row items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 inline-block text-green-700"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              Pick-up and drop-off in Reykjavik.
            </p>
            <p className="mt-4 text-black lg:mx-auto flex flex-row items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 inline-block text-green-700"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              Local certified guide from Norse Adventures
            </p>
            <p className="mt-4 text-black lg:mx-auto flex flex-row items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 inline-block text-green-700"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              Experienced and licensed mountain vehicle driver.
            </p>
            <p className="mt-4 text-black lg:mx-auto flex flex-row items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 inline-block text-green-700"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              Private 4×4 mountain vehicle for the group to get in and out of the highlands.
            </p>
            <p className="mt-4 text-black lg:mx-auto flex flex-row items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 inline-block text-green-700"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              Support vehicle that transfers the overnight luggage between huts.
            </p>
            <p className="mt-4 text-black lg:mx-auto flex flex-row items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 inline-block text-green-700"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              Vehicle fuel.
            </p>
            <p className="mt-4 text-black lg:mx-auto flex flex-row items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 inline-block text-green-700"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              Accommodations in mountain huts.
            </p>
            <p className="mt-4 text-black lg:mx-auto flex flex-row items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 inline-block text-green-700"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              All meals for the duration of the trip.
            </p>
            <p className="mt-4 text-black lg:mx-auto flex flex-row items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 inline-block text-green-700"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              All the cooking equipment.
            </p>
            <p className="mt-4 text-black lg:mx-auto flex flex-row items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 inline-block text-green-700"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              Sleeping bag.
            </p>
            <p className="mt-4 text-black lg:mx-auto flex flex-row items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 inline-block text-green-700"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              Showers in the mountain huts.
            </p>
            <p className="mt-4 text-black lg:mx-auto flex flex-row items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 inline-block text-green-700"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              Admission fees and parking fees.
            </p>
            <p className="mt-4 text-black lg:mx-auto flex flex-row items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 inline-block text-green-700"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              Agency support 24/7.
            </p>
          </div>
          <div className="">
            <p className="text-xl font-bold text-black lg:mx-auto">
              Not included
            </p>
            <p className="mt-4 text-black lg:mx-auto flex flex-row items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-5 w-5 inline-block text-red-700">
                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm3 10.5a.75.75 0 0 0 0-1.5H9a.75.75 0 0 0 0 1.5h6Z" clip-rule="evenodd" />
              </svg>
              International flights to and from Iceland.
            </p>
            <p className="mt-4 text-black lg:mx-auto flex flex-row items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-5 w-5 inline-block text-red-700">
                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm3 10.5a.75.75 0 0 0 0-1.5H9a.75.75 0 0 0 0 1.5h6Z" clip-rule="evenodd" />
              </svg>
              Airport transfers to and from to Reykjavik.
            </p>
            <p className="mt-4 text-black lg:mx-auto flex flex-row items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-5 w-5 inline-block text-red-700">
                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm3 10.5a.75.75 0 0 0 0-1.5H9a.75.75 0 0 0 0 1.5h6Z" clip-rule="evenodd" />
              </svg>
              Pick-up and drop-off from your hotel.
            </p>
            <p className="mt-4 text-black lg:mx-auto flex flex-row items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-5 w-5 inline-block text-red-700">
                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm3 10.5a.75.75 0 0 0 0-1.5H9a.75.75 0 0 0 0 1.5h6Z" clip-rule="evenodd" />
              </svg>
              Accommodations in Reykjavik before the trip.
            </p>
            <p className="mt-4 text-black lg:mx-auto flex flex-row items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-5 w-5 inline-block text-red-700">
                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm3 10.5a.75.75 0 0 0 0-1.5H9a.75.75 0 0 0 0 1.5h6Z" clip-rule="evenodd" />
              </svg>
              Any meals in Reykjavik before the trip.
            </p>
            <p className="mt-4 text-black lg:mx-auto flex flex-row items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-5 w-5 inline-block text-red-700">
                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm3 10.5a.75.75 0 0 0 0-1.5H9a.75.75 0 0 0 0 1.5h6Z" clip-rule="evenodd" />
              </svg>
              Lockers in Reykjavík to store luggage that is not taken on the trip.
            </p>
            <p className="mt-4 text-black lg:mx-auto flex flex-row items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-5 w-5 inline-block text-red-700">
                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm3 10.5a.75.75 0 0 0 0-1.5H9a.75.75 0 0 0 0 1.5h6Z" clip-rule="evenodd" />
              </svg>
              Personal hiking gear.
            </p>
            <p className="mt-4 text-black lg:mx-auto flex flex-row items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-5 w-5 inline-block text-red-700">
                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm3 10.5a.75.75 0 0 0 0-1.5H9a.75.75 0 0 0 0 1.5h6Z" clip-rule="evenodd" />
              </svg>
              Trip insurance.
            </p>
            <p className="mt-4 text-black lg:mx-auto flex flex-row items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-5 w-5 inline-block text-red-700">
                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm3 10.5a.75.75 0 0 0 0-1.5H9a.75.75 0 0 0 0 1.5h6Z" clip-rule="evenodd" />
              </svg>
              Anything before or after the trip.
            </p>
            <p className="mt-4 text-black lg:mx-auto flex flex-row items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-5 w-5 inline-block text-red-700">
                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm3 10.5a.75.75 0 0 0 0-1.5H9a.75.75 0 0 0 0 1.5h6Z" clip-rule="evenodd" />
              </svg>
              Everything not specified in "Included".
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 p-4 laptop:p-16 w-full max-w-[1408px] pt-0 laptop:pt-0">
          <div className="flex flex-col">
            <section className="mx-auto max-w-7xl w-full">
              <div className="mb-8 lg:text-center">
                <p className="text-xl font-bold text-black lg:mx-auto">
                  FAQ - Frequently Asked Questions
                </p>
              </div>

              <Accordion bordered>
                <Accordion.Panel header="How fit do I need to be?" eventKey={1}>
                  <Placeholder.Paragraph />
                </Accordion.Panel>
                <Accordion.Panel header="What do I need to bring?" eventKey={2}>
                  <Placeholder.Paragraph />
                </Accordion.Panel>
                <Accordion.Panel header="What are the mountain huts like?" eventKey={3}>
                  <Placeholder.Paragraph />
                </Accordion.Panel>
                <Accordion.Panel header="How are the meals like during the trip?" eventKey={4}>
                  <Placeholder.Paragraph />
                </Accordion.Panel>
                <Accordion.Panel header="Can dietary requirements be accommodated?" eventKey={5}>
                  <Placeholder.Paragraph />
                </Accordion.Panel>
                <Accordion.Panel header="Are there any river crossings during the trip?" eventKey={6}>
                  <Placeholder.Paragraph />
                </Accordion.Panel>
                <Accordion.Panel header="Group sizes and age requirements" eventKey={7}>
                  <Placeholder.Paragraph />
                </Accordion.Panel>
                <Accordion.Panel header="Getting there and meeting location" eventKey={8}>
                  <Placeholder.Paragraph />
                </Accordion.Panel>
                <Accordion.Panel header="Deposits and cancellation policy" eventKey={9}>
                  <Placeholder.Paragraph />
                </Accordion.Panel>
              </Accordion>
            </section>
          </div>
        </div>

        <Reviews />

        <Footer />
      </div>
    );
  }
}
